.booking {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0  auto;
  color: var(--color-light-text);
  -webkit-box-shadow:  0px 0px 149px rgba(0, 0, 0, 0.15);
  -moz-box-shadow:  0px 0px 149px rgba(0, 0, 0, 0.15);
  box-shadow:  0px 0px 149px rgba(0, 0, 0, 0.15);
  border-radius: 25px;
  background: url('assets/images/bg-2.svg') 99.3% 59.3% no-repeat fixed, url('assets/images/bg-5.svg') 94.3% 17.2% no-repeat fixed, url('assets/images/bg-1.svg') 0% 20% no-repeat fixed;
  @media (min-width: 320px) {
    max-width: 400px;
  }
  @media (min-width: 989px) {
    max-width: 640px;
  }
  &.sent {
    .booking {
      &__title {
        opacity: 0;
      }
      &__form {
        opacity: 0;
      }
      &__sent {
        display: block;
      }
    }
  }
  &__content {
    position: relative;
    z-index: 2;
    border-radius: 25px;
    width: 100%;
    padding: 0 80px 24px;
    background-color: var(--color-bg);
    @media (max-width: 490px) {
      padding: 0 16px 24px;
    }
    @media (max-width: 988px) {
      padding: 0 32px 24px;
    }
  }
  &__header {
    margin: 0 auto;
    margin-bottom: 29px;
    padding: 23px 63px 23px 51px;
    width: 76%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.076) 0%, rgba(0, 0, 0, 0) 100%), #FFFFFF;
    box-shadow: 0px 35px 32px -34px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 25px 25px;
    @media (max-width: 490px) {
      margin-bottom: 25px;
      padding: 14px 10% 10px 8%;
    }
    @media (max-width: 420px) {
      padding: 10px 10% 10px 8%;
    }
    @media (max-width: 989px) {
      padding: 19px 10% 19px 8%;
    }
  }
  &__logo {
    margin: 0 auto;
    width: 200px;
    max-height: 89px;
    margin-bottom: 0.1rem;
    img {
      width: 200px;
      height: 89px;
    }
    @media (min-width: 989px) {
      width: 262px;
      img {
        width: 262px;
      }
    }
    @media (max-width: 420px) {
      width: 150px;
      img {
        width: 150px;
      }
    }
  }
  &__title {
    margin-bottom: 0.2rem;
    padding-left: 12px;
    text-align: center;
    font-weight: 400;
    font-family: 'BebasNeue', sans-serif;
    font-size: 1.5rem;
    line-height: 1.95rem;
    letter-spacing: 0.263em;
    @include transition;
    @media (max-width: 440px) {
      font-size: 1.5rem;
    }
    @media (max-width: 420px) {
      font-size: 1.1rem;
    }
    @media (min-width: 989px) {
      font-size: 1.95rem;
    }
  }
  &__form {
    @include transition;
  }
  &__sent {
    display: none;
    opacity: 0;
    @include transition;
    &.view {
      opacity: 1;
    }
  }
  &__sent-caption {
    margin-bottom: 1rem;
    text-align: center;
    font-weight: 600;
    font-size: 2rem;
    line-height: 1.5;
    letter-spacing: -2px;
  }
  &__sent-descr {
    margin-bottom: 1rem;
    text-align: center;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.15em;
  }
  &__decor {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  &__decor-span {
    position: absolute;
    &_top {
      width: 64px;
      height: 145px;
      top: -87px;
      right: -12px;
      background-image: url('assets/images/bg-5.svg');
      background-repeat: no-repeat;
      background-size: cover;
    }
    &_left {
      width: 237px;
      height: 446px;
      top: 202px;
      left: -136px;
      background-image: url('assets/images/bg-2.svg');
      background-repeat: no-repeat;
      background-size: cover;
    }
    &_right {
      width: 224px;
      height: 306px;
      bottom: -122px;
      right: -111px;
      background-image: url('assets/images/bg-1.svg');
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  .hide {
    display: none;
  }
}
