
.tooltip-icon {
  display: flex;
  position: absolute;
  top: calc(50% - 6px);
  right: 0;
  width: 36px;
  height: 25px;
  padding: 0;
  border-radius: 50%;
  border: none;
  outline: none;
  background: white;
  cursor: pointer;
  @media (min-width: 590px) {
    padding-right: 4px;
  }
  &:focus-visible svg path {
    fill: var(--color-light-fill);
    stroke: var(--color-fill);
  }
  &:focus-visible svg > path + path {
    fill: var(--color-black);
    stroke: transparent;
  }
  svg {
    margin-left: auto;
    width: initial;
    height: 19px;
    background: white;
  }
  svg path {
    transition: fill .3s ease;
  }
}

.tippy-content {
  max-width: 200px;
  padding: 7px;
  border-radius: 5px;
  text-align: center;
  font-family: 'Open Sans', sans-serif  ;
  font-size: 12px;
  line-height: 16px;
  color: white;
  background-color: var(--color-fill);
}

.tippy-box[data-theme~='main'] {
  color: var(--white-color);
}

.tippy-box[data-theme~='main'][data-placement^='top'] > .tippy-arrow::before {
  border-top-color: var(--color-fill);
}
