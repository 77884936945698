@import '_mixin.scss';
@font-face {
	font-family: BebasNeue;
	font-display: swap;
	src: url('assets/fonts/BebasNeueRegular.woff') format('woff2'),url('assets/fonts/BebasNeueRegular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: SFProDisplay;
	font-display: swap;
	src: url('assets/fonts/SFProDisplay-Regular.woff2') format('woff2'),url('assets/fonts/SFProDisplay-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: SFProDisplay;
	font-display: swap;
	src: url('assets/fonts/SFProDisplay-Medium.woff2') format('woff2'),url('assets/fonts/SFProDisplay-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}
@font-face {
  font-family: SFProDisplay;
  font-display: swap;
  src: url('assets/fonts/SFProDisplay-Semibold.woff2') format('woff2'),url('assets/fonts/SFProDisplay-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
:root {
  // цвет
  --color-black: #000;
  --color-white: #FFF;
  --color-light-gray-fill: #ECECEC;
  --color-gray-fill: #CACACA;
  --color-dark-gray-fill: #FFF;
  --color-very-light-fill: rgb(235, 221, 165);
  --color-light-fill: #c41111;
  --color-fill: #C20000;
  --color-dark-fill: #a50303;
  --color-light-text: #444;
  --color-gray-text: #999;
  --color-text: #333;
  --color-fill-p: #C20000;

  --color-bg: #FFFFFF;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
  list-style: none;
  text-decoration: none;
}
html body {
  font-family: 'SFProDisplay', sans-serif;
}
body {
  position: relative;
  min-width: 320px;
  background-color: var(--color-bg);
  background: url('assets/images/bg-3.svg') 100% 59.3% no-repeat fixed, url('assets/images/bg-4.svg') 94.3% 17.2% no-repeat fixed, url('assets/images/bg-6.svg') 0% 15% no-repeat fixed;
  @media (max-width: 988px) {
    background: url('assets/images/bg-3.svg') 100% 46.3% no-repeat fixed, url('assets/images/bg-4.svg') 94.3% 17.2% no-repeat fixed, url('assets/images/bg-6.svg') 0% 15% no-repeat fixed;
  }
}
img,
svg {
  width: 100%;
}

h1,h2,h3,h4,p,ul,li {
  margin: 0;
}
div .active {
  display: block;
}
div .deactivate {
  display: none;
}
.container {
  min-height: 100vh;
  width: 100%;
  min-width: 320px;
  margin: 0 auto;
  padding-left: 7px;
  overflow: hidden;
  @media (min-width: 989px) {
    padding-top: 123px;
    padding-left: 7px;
    padding-right: 7px;
  }
  @media (max-width: 490px) {
    padding-top: 40px;
    padding-right: 7px;
  }
  @media (max-width: 988px) {
    padding-top: 70px;
  }
}

.second-title {
  font-weight: normal;
  font-size: 1.75em;
  line-height: 38px;
}
.btn {
  border-color: transparent;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}

.logo {
  display: flex;
}

.list-reset {
  padding: 0;
  margin: 0;
  list-style: none;
}

.link-reset {
  line-height: 1;
  color: inherit;
  text-decoration: none;
  text-align: center;
}

.top-wrap {
  position: relative;
}
// паттерн для скрытия
div .visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
}

.overflow-hidden {
  overflow: hidden;
}
// @media (min-width: 480px) {
//   .container {
//     padding-right: 50px;
//     padding-left: 50px;
//   }
//   .second-title {
//     font-weight: normal;
//     font-size: 3em;
//     line-height: 1.5em;
//   }
// }

// @media (min-width: 1025px) {
//   .container {
//     padding-right: 100px;
//     padding-left: 100px;
//   }
// }
// @media (min-width: 1480px) {
//   .container {
//     padding-right: 160px;
//     padding-left: 160px;
//   }

// }
