.form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items:stretch;
  width: 100%;
  padding: 0;
  color: var(--color-light-text);
  &.hide {
    display: none;
  }
  &__wrap {
    position: relative;
    margin-bottom: 2.4rem;
    width: 100%;
    padding-left: 58px;
    &_strait {
      width: 48%;
    }
    &_name {
      margin-bottom: 2.3rem;
    }
    &_phone {
      padding-left: 105px;
      width: 52.7%;
    }
    &_amount {
      padding-left: 144px;
      width: 42%;
    }
    &_date {
      padding-left: 105px;
      width: 52.7%;
    }
    &_time {
      padding-left: 81px;
      width: 42%;
    }
    &_adress {
      padding-left: 81px;
    }
    &_wishes {
      padding-left: 120px;
      margin-bottom: 3.5rem;
    }
    @media (max-width: 988px) {
      padding-left: 0;
      margin-bottom: 1.5rem;
    }
    &.active {
      .form {
        &__label {
          top: -4px;
          font-weight: 600;
          font-size: 12px;
          color: var(--color-fill);
          background-color: transparent;
        }
      }
    }
  }
  &__label {
    display: block;
    position: absolute;
    top: calc(50% + 8px);
    left: 0px;
    transform: translateY(-50%);
    white-space: nowrap;
    overflow: hidden;
    font-weight: 500;
    font-size: 1.125em;
    line-height: 1.31em;
    letter-spacing: 0.01em;
    pointer-events: none;
    transition: all 0.3s ease;
    -webkit-appearance: none;
    @media (max-width: 988px) {
      background-color: #fff;
      width: 100%;
      top: 20px;
      padding-top: 8px;
    }
    @media (max-width: 420px) {
      font-size: 0.8rem;
      top: 24px;
    }
  }
  &__input {
    padding: 12px 0 5px;
    min-height: 42px;
    width: 100%;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid var(--color-light-text);
    background: transparent;
    @include transition;
    &:focus {
      border-color: var(--color-fill);
      &~.form__label {
        color: var(--color-fill);
      }
    }
    -webkit-appearance: none;
    @media (min-width: 590px) {
      &:hover {
        border-color: var(--color-fill);
        &~.form__label {
          color: var(--color-fill);
        }
      }
    }
    @media (max-width: 988px) {
      &:hover {
        border-color: var(--color-fill);
        &~.form__label {
          top: -4px;
          font-weight: 600;
          font-size: 12px;
          color: var(--color-fill);
          background-color: transparent;
        }
      }
    }
    @media (max-width: 490px) {
      font-size: 0.9rem;
    }
  }
  &__select {
    position: relative;
    width: 100%;
    padding-bottom: 0;
    @media (min-width: 590px) {

    }
  }
  &__select-trigger {
    padding: 12px 0 5px;
    min-height: 42px;
    width: 100%;
    background: transparent;
    cursor: pointer;
  }
  &__options {
    display: none;
    position: absolute;
    top: 100%;
    z-index: 1;
    left: 0;
    width: 100%;
    background-color: var(--color-bg);
    border-radius: 0;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }
  &__option {
    padding: 10px;
    color: var(--color-text);
    cursor: pointer;
  }
  &__select.active &__options {
    display: block;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  input[type="number"]:hover,
  input[type="number"]:focus {
      -moz-appearance: number-input;
  }
  &__notice {
    margin: 0 auto;
    margin-bottom: .5rem;
    max-width: 285px;
    font-size: 12px;
    line-height: 125%;
    text-align: center;
  }
  &__validation-errors {
    width: 100%;
    min-height: 2rem;
    text-align: center;
    font-size: 12px;
    color: #FF8A80;
    padding-bottom: 10px;
  }
  &__btn {
    display: block;
    position: relative;
    margin: 0 auto;
    margin-bottom: 1.5rem;
    width: 300px;
    height: 53px;
    border: 1px solid var(--color-fill);
    border-radius: 28px;
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #fff;
    background-color: var(--color-fill);
    text-decoration: none;
    outline: none;
    overflow: hidden;
    @include transition;
    -webkit-appearance: none;
    @media (min-width: 1024px) {
      &:hover {
        background-color: var(--color-light-fill);
        opacity: .7;
      }
    }
    &:focus-visible {
      background-color: var(--color-light-fill);;
    }
    &:active {
      background-color: var(--color-dark-fill);
      opacity: 1;
    }
    &:disabled {
      opacity: 0.5;
      @media (min-width: 1024px) {
        &:hover {
          background-color: var(--color-fill);
          opacity: .5;
        }
      }
    }
  }
}

.is-invalid {
  border-color: var(--color-fill-p);
  &~ label {
    color: var(--color-fill-p);
  }
}
