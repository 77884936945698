.custom-checkbox {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  margin-bottom: 0.8rem;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.05em;
  font-weight: 400;
  &__label {
    position: relative;
    padding-left: 38px;
    width: 100%;
    align-items: center;
    user-select: none;
    font-size: 1em;
    line-height: 1.13em;
    letter-spacing: 0.03em;
    cursor:pointer;
    &::before {
      content: "";
      position: absolute;
      left: 0px;
      top: calc(50% - 12px);
      display: inline-block;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      flex-shrink: 0;
      flex-grow: 0;
      border: 1px solid var(--color-light-text);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 70% 90%;
    }
  }
  &__input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  }
  &__input:checked ~ {
    margin-left: 25px;
    border: 1px solid var(--color-fill);
  }
  &__policy-link {
    text-decoration: underline;
  }
  &__input:checked+label {
    &::before {
      border-color: var(--color-fill);
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' viewBox='0 0 14 14'%3e%3cpath fill='rgb(194, 0, 0)' d='M13.4616 0.19043C12.9139 -0.164675 12.1829 -0.00877504 11.8281 0.53845L5.21027 10.7393L1.98283 7.75512C1.50371 7.31222 0.756884 7.34136 0.313987 7.82047C-0.128911 8.2992 -0.099778 9.04681 0.379339 9.48931L4.61698 13.4073C4.61698 13.4073 4.73863 13.512 4.79493 13.5486C4.99335 13.6777 5.21618 13.7392 5.43664 13.7392C5.82324 13.7392 6.20236 13.5494 6.42873 13.2006L13.8096 1.82384C14.1647 1.27661 14.0088 0.545142 13.4616 0.19043Z'/%3e%3c/svg%3e");
      background-size: 14px 12px;
    }
  }
}
